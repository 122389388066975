import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => {
    return <StaticImage
    src="../images/morden-hall.png"
    alt="Morden Hall"
    placeholder="blurred"
    loading="eager"
    objectFit="contain"
    width={125}
    style={{
    }} />

}

export default Logo