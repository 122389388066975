import * as React from 'react'
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout'

const FaqPage = () => {
  const { t } = useTranslation("Shopping");

  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>
        
        <h2>{t("regents.title")}</h2>
        <span>{t("regents.description")}</span><p/>
        
        <h2>{t("marylebone.title")}</h2>
        <span>{t("marylebone.description")}</span><p/>
        
        <h2>{t("bond.title")}</h2>
        <span>{t("bond.description")}</span><p/>
        
        <h2>{t("coventGarden.title")}</h2>
        <span>{t("coventGarden.description")}</span><p/>
        
        <h2>{t("kingsRoad.title")}</h2>
        <span>{t("kingsRoad.description")}</span><p/>
        
        <h2>{t("knightsbridge.title")}</h2>
        <span>{t("knightsbridge.description")}</span><p/>
        
        <h2>{t("westfield.title")}</h2>
        <span>{t("westfield.description")}</span><p/>
        
        <h2>{t("iconGreenwich.title")}</h2>
        <span>{t("iconGreenwich.description")}</span><p/>
        
        <h2>{t("wembley.title")}</h2>
        <span>{t("wembley.description")}</span><p/>
        
        <h2>{t("bicester.title")}</h2>
        <span>{t("bicester.description")}</span><p/>
    </Layout>
  )
}

export default FaqPage