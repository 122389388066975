import React, { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout'
import styled from 'styled-components'

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-transform: uppercase;
  width: 100%;
  align-items: center;
  gap: 10px
`
const Input = styled.input`
  height: 3em;
  width: 20em;
  border: 1px solid rgba(17, 17, 17, 0.1);
  flex-grow: 100;
  padding-left: 1em;
`

const Button = styled.button`
  margin: 0.5em 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 3em;
  line-height: 3em;
  transition: all 0.3s;
  
  &:before, &:after {
    content: '';
    position: absolute;
    transition: all 0.3s;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 105%;
    z-index: 1;
  }
  
  &:before {
    left: 4px;
    z-index: 1;
    opacity: 0;
    background: rgba(17, 17, 17, 0.1);
    transform: scale(0.1, 1);
  }
  
  &:after {
    transition: all 0.3s;
    border: 1px solid rgba(17, 17, 17, 0.1);
  }
  
  &:hover:before {
    opacity: 1;
    transform: scale(1, 1);
  }
  
  &:hover:after {
    transform: scale(1, .1);
    opacity: 0;
  }
`
const CenteredContent = styled.div`    
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;
`

const VideoContainer = styled.div`    
position: relative;
overflow: hidden;
padding-top: 56.25%;
`

const Video = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border: 0;
    `

const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const _handleSubmit = (e) => {
    e.preventDefault()

    console.log('submit', email)

    addToMailchimp(email)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)

        if (result !== 'success') {
          throw msg
        }
        setSubscribed(true)
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  
  const { t } = useTranslation("Home");

  return (
    <Layout pageTitle={t("title")}>
      <div style={{padding:'75% 0 0 0', position: 'relative'}}
        ><iframe src="https://player.vimeo.com/video/727895923?h=e7b63fa351&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: 'absolute', top:'0', left:'0', width:'100%', height:'100%'}} title="Ida &amp;amp; Alex - 29.07.22"></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      <h1>29.07.22</h1>
      <p>{t("p1")}</p>
      <p>{t("p2")} <Link to="/venue">{t("venueLinkText")}</Link></p>
      <p>{t("p3")} <Link to="/faqs">{t("faqLinkText")}</Link></p>
      <p>
        {t("p4.1")}&nbsp;
        <Link to="/transport">{t("p4.transportLinkText")}</Link>&nbsp;
        {/* {t("p4.2")}&nbsp;
        <Link to="/things-to-do">{t("p4.thingsToDoLinkText")}</Link> 
        {t("p4.3")} */}
      </p>

      <p>{t("subscribeIntro")}</p>

      {subscribed
      ? <p>{t("subscribeSuccess")}</p>
        : <Form onSubmit={_handleSubmit}>
          <Input
            type="email"
            onChange={e => setEmail(e.target.value)}
            placeholder={t("email")}
            name="email"
          />
          <br />
          <Button type="submit"><span>{t("subscribeButton")}</span></Button>
        </Form>
      }
    </Layout>
  )
}

export default IndexPage