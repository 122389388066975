import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout'

const InfoPage = () => {
  return (
    <Layout pageTitle="Home Page">
    <StaticImage src="../images/morden-hall.png"
      placeholder="blurred" loading="eager" 
      style={{
        minWidth: "250px",
        width: "20vw",
        // You can set a maximum height for the image, if you wish.
        // maxHeight: 600,
      }}/>
    <h1>29.07.22</h1>
      <p>I'm making this by following the Gatsby Tutorial.</p>
    </Layout>
  )
}

export default InfoPage