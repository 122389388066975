import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby"
import Layout from '../components/layout'

const VenuePage = () => {
  const { t } = useTranslation("Venue");

  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>
      <p>{t("intro")}</p>
      <span>
        {t("address.title")}<br />
        {t("address.line1")}<br />
        {t("address.line2")}
      </span>
      <p>{t("directions.intro")}</p>

      <h2>{t("directions.taxi.title")}</h2>
      <span><Link to="/transport">{t("directions.taxi.p1LinkText")}</Link> {t("directions.taxi.p1")}</span><p/>

      <h2>{t("directions.car.title")}</h2>
      <span>{t("directions.car.p1")}</span>
      <span>{t("directions.car.p2")}</span><p/>

      <h2>{t("directions.tube.title")}</h2>
      <span>{t("directions.tube.p1")}</span><p/>

      <h2>{t("directions.train.title")}</h2>
      <span>{t("directions.train.p1")}</span><p/>

      <h2>{t("directions.bus.title")}</h2>
      <span>{t("directions.bus.p1")}</span>
      <ul>
        <li>{t("directions.bus.routes.80")}</li>
        <li>{t("directions.bus.routes.118")}</li>
        <li>{t("directions.bus.routes.157")}</li>
        <li>{t("directions.bus.routes.164")}</li>
        <li>{t("directions.bus.routes.201")}</li>
        <li>{t("directions.bus.routes.470")}</li>
      </ul>
      <span>{t("directions.bus.routes.93")}</span><p/>
      <StaticImage
          src="../images/morden-hall-map.jpg"
          alt="Morden Hall"
          placeholder="blurred"
          loading="eager"
          style={{
            minWidth: "250px",
            width: "100%"
          }} />
    </Layout>
  )
}

export default VenuePage