import i18n from "i18next"
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend'; // fallback http load
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    // have a common namespace used around the full app
    ns: ["FAQs", "Home", "NavbarLinks", "TransportAndHotels", "Venue"],

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      useSuspense: false
    },
    backend: {
      backends: [
        LocalStorageBackend,  // primary backend
        HttpApi               // fallback backend
      ],
      backendOptions: [{
        /* options for primary backend */
        defaultVersion: '1.3',
      }, {
        /* options for secondary backend */
      }]
    }
  })

export default i18n