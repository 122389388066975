import * as React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import Layout from '../components/layout'

const TimeTable = styled.p`
width: 100%;
`
const Row = styled.span`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
height: 100%;
gap: 1em;
`
const Time = styled.span`    
flex-basis: 6ch;
`
const Event = styled.span`    
flex: 1;
`
const NewContent = styled.div`    
  color: goldenrod;
`

const FaqPage = () => {
  const { t } = useTranslation("FAQs");

  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>
        <h2>{t("getting-there.question")}</h2>
        <span>{t("getting-there.answer")}</span><p/>
        
        <h2>{t("parking.question")}</h2>
        <span>{t("parking.answer")}</span><p/>
        
        <h2>{t("arrival-time.question")}</h2>
        <span>{t("arrival-time.answer")}</span><p/>
        
        <NewContent>
        <h2>{t("dresscode.question")}</h2>
        <span>{t("dresscode.answer")}</span><p/>
        </NewContent>
        
        <NewContent>
        <h2>{t("bar.question")}</h2>
        <span>{t("bar.answer")}</span><p/>
        </NewContent>
        
        <h2>{t("wedding-type.question")}</h2>
        <span>{t("wedding-type.answer")}</span><p/>
        
        <h2>{t("language.question")}</h2>
        <span>{t("language.answer")}</span><p/>
        
        <h2>{t("ceremony.question")}</h2>
        <span>{t("ceremony.answer")}</span><p/>
        
        <h2>{t("reina.question")}</h2>
        <span>{t("reina.answer")}</span><p/>
        
        <h2>{t("gifts.question")}</h2>
        <span>{t("gifts.answer")}</span><p/>
        
        <NewContent>
        <h2>{t("timetable.question")}</h2> 
        <TimeTable>
        <Row><Time>1-1.30pm</Time><Event> {t("timetable.answer.1pm")}</Event></Row>
        <Row><Time>2-2.30pm</Time><Event> {t("timetable.answer.2pm")}</Event></Row>
        <Row><Time>2.30-4pm</Time><Event> {t("timetable.answer.230pm")}</Event></Row>
        <Row><Time>4-6.45pm</Time><Event> {t("timetable.answer.4pm")}</Event></Row>
        <Row><Time>6.45pm</Time><Event> {t("timetable.answer.645pm")}</Event></Row>
        <Row><Time>8.30pm</Time><Event> {t("timetable.answer.830pm")}</Event></Row>
        <Row><Time>9pm</Time><Event> {t("timetable.answer.9pm")}</Event></Row>
        <Row><Time>Midnight</Time><Event> {t("timetable.answer.midnight")}</Event></Row>
        </TimeTable>
        </NewContent>
        
        <h2>{t("children.question")}</h2>
        <span>{t("children.answer")}</span>
    </Layout>
  )
}

export default FaqPage