import * as React from 'react'
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout'

const HotelsPage = () => {
  const { t } = useTranslation("Hotels");

  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>

      <p>{t("intro")}</p>
      <h2>{t("premierInn.title")}</h2>
      <span>Wimbledon South</span>
      <span>0871 527 8684</span>
      <a href="https://www.premierinn.com/gb/en/hotels/england/greater-london/london/london-wimbledon-south.html">{t("hotelWebsite")}</a><br/>
      <span>{t("premierInn.description")}</span><p/>

      <h2>{t("holidayInn.title")}</h2>
      <span>Wimbledon South</span>
      <span>020 8545 7300</span>
      <a href="https://www.ihg.com/holidayinnexpress/hotels/gb/en/london/lonws/hoteldetail">{t("hotelWebsite")}</a><br/>
      <span>{t("holidayInn.description")}</span><p/>
        

      <h2>{t("antoinette.title")}</h2>
      <span>Wimbledon</span>
      <span>0844 567 8955</span>
      <a href="https://www.antoinettehotel.com/">{t("hotelWebsite")}</a><br/>
      <span>{t("antoinette.description")}</span><p/>
        
      <h2>{t("cannizaro.title")}</h2>
      <span>Wimbledon</span>
      <span>0330 016 0390</span>
      <a href="https://www.hotelduvin.com/locations/wimbledon/">{t("hotelWebsite")}</a><br/>
      <span>{t("cannizaro.description")}</span><p/>
        
      <h2>{t("richmondHill.title")}</h2>
      <span>Richmond</span>
      <span>020 8940 2247</span>
      <a href="http://www.richmondhill-hotel.co.uk/">{t("hotelWebsite")}</a><br/>
      <span>{t("richmondHill.description")}</span><p/>
        
      <h2>{t("petersham.title")}</h2>
      <span>Richmond</span>
      <span>020 8939 1010</span>
      <a href="http://www.petershamhotel.co.uk/">{t("hotelWebsite")}</a><br/>
      <span>{t("petersham.description")}</span><p/>
    </Layout>
  )
}

export default HotelsPage