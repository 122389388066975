import * as React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import Layout from '../components/layout'

const New = styled.div`    
  color: goldenrod;
`

const FaqPage = () => {
  const { t } = useTranslation("Sightseeing");

  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>
        
        <h2>{t("skygarden.title")}</h2>
        <span>{t("skygarden.description")}</span><p/>
        
        <h2>{t("primroseHill.title")}</h2>
        <span>{t("primroseHill.description")}</span><p/>
        
        <h2>{t("abbeyRoad.title")}</h2>
        <span>{t("abbeyRoad.description")}</span><p/>
        
        <New>
        <h2>{t("columbiaFlower.title")}</h2>
        <span>{t("columbiaFlower.description")}</span><p/>
        </New>
        
        <h2>{t("battersea.title")}</h2>
        <span>{t("battersea.description")}</span><p/>
        
        <h2>{t("hampstead.title")}</h2>
        <span>{t("hampstead.description")}</span><p/>
        
        <h2>{t("harryPotter.title")}</h2>
        <span>{t("harryPotter.description")}</span><p/>

        <New>
        <h2>{t("greenwich.title")}</h2>
        <ul>
        <li>{t("greenwich.1")}</li>
        <li>{t("greenwich.2")}</li>
        <li>{t("greenwich.3")}</li>
        <li>{t("greenwich.4")}</li>
        <li>{t("greenwich.5")}</li>
        <li>{t("greenwich.6")}</li>
        <li>{t("greenwich.7")}</li>
        <li>{t("greenwich.8")}</li>
        <li>{t("greenwich.9")}</li>
        <li>{t("greenwich.10")}</li>
        </ul>
        </New>
    </Layout>
  )
}

export default FaqPage