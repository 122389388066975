import React from "react"
import { useTranslation } from "react-i18next"
import Flag from '../components/flag'

const LanguageSwitcher = ({className}) => {
  const { i18n } = useTranslation();
  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }
  const renderLanguageChoice = ({ code, label }) => {
    return (
      i18n.language !== code &&
      <>
      <Flag key={code + 'flag'} country={code}/>
      <button
        key={code + 'label'}
        onClick={() => handleChangeLanguage(code)}
      >
        {label}
      </button>
      </>
    )
  }

  const languages = [
    { code: "en", label: "English" },
    { code: "fi", label: "Suomi" },
  ]
  return (
    <div className={className}>
      {languages.map(language => renderLanguageChoice(language))}
    </div>
  )

}
export default LanguageSwitcher