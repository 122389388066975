import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import "../components/i18n"
import Layout from '../components/layout'
import Flag from '../components/flag'

const CenteredContent = styled.div`    
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
`

const StyledLink = styled(Link)`
  text-decoration: none;  
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-transform: uppercase;
  width: 100%;
`

const Button = styled.div`
  margin: 0.5em 1em;
  text-align: center;
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 50px;
  line-height: 50px;
  transition: all 0.3s;
  
  &:before, &:after {
    content: '';
    position: absolute;
    transition: all 0.3s;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  &:before {
    left: 4px;
    z-index: 1;
    opacity: 0;
    background: rgba(17, 17, 17, 0.1);
    transform: scale(0.1, 1);
  }
  
  &:after {
    transition: all 0.3s;
    border: 1px solid rgba(17, 17, 17, 0.1);
  }
  
  &:hover:before {
    opacity: 1;
    transform: scale(1, 1);
  }
  
  &:hover:after {
    transform: scale(1, .1);
    opacity: 0;
  }
`
const ButtonText = styled.span`
  padding-left: 0.5em;
`

const IndexPage = () => {
  const { i18n } = useTranslation();

  const switchLanguageToEn = () => {
    i18n.changeLanguage("en")
  }

  const switchLanguageToFi = () => {
    i18n.changeLanguage("fi")
  }

  return (
    <Layout pageTitle="Home Page" showNav={false}>
      <CenteredContent>
        <StaticImage
          src="../images/morden-hall.png"
          alt="Morden Hall"
          placeholder="blurred"
          loading="eager"
          style={{
            minWidth: "250px",
            width: "20vw"
          }} />
        <h1>29.07.22</h1>
        <p>Ida & Alex @ Morden Hall.</p>

        <ButtonContainer>
          <StyledLink to="/home">
            <Button onClick={switchLanguageToEn}>
              <Flag country="en" />
              <ButtonText>English</ButtonText>
            </Button>
          </StyledLink>
          <StyledLink to="/home">
            <Button onClick={switchLanguageToFi}>
              <Flag country="fi" />
              <ButtonText>Suomi</ButtonText>
            </Button>
          </StyledLink>
        </ButtonContainer>
      </CenteredContent>
    </Layout>
  )
}

export default IndexPage