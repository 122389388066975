import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout'
import contactlessCardSource from "../images/contactless-card.svg"
import styled from 'styled-components'

const FloatingImageRight = styled.img`
  float: right;
`

const TransportAndHotelsPage = () => {
  const { t } = useTranslation("TransportAndHotels");

  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>

      <h2>{t("gettingAround.title")}</h2>
      <span>
        {t("gettingAround.p1.1")}&nbsp;
        <a href="https://tfl.gov.uk/travel-information/visiting-london/">
          {t("gettingAround.p1.tflVisitingLondonLinkText")}
        </a>&nbsp;
        {t("gettingAround.p1.2")}&nbsp;
        <a href="https://londonblog.tfl.gov.uk/category/walking/?intcmp=65895">
          {t("gettingAround.p1.tflWalkingLinkText")}
        </a>&nbsp;
        {t("gettingAround.p1.3")}
      </span><p/>

      <span>
        {t("gettingAround.p2.1")}&nbsp;
        <a href="https://tfl.gov.uk/status-updates/planned-works-calendar">
          {t("gettingAround.p2.plannedWorksLinkText")}
        </a>&nbsp;
        {t("gettingAround.p2.2")}&nbsp;
      </span><p/>

      <h2>{t("gettingAround.citymapper.title")}</h2>
      <span>{t("gettingAround.citymapper.p1")}</span><p/>
      <span>{t("gettingAround.citymapper.p2")}</span><p/>
      <span>{t("gettingAround.citymapper.p3")}</span><p/>

      <h2>{t("gettingAround.howToPay.title")}</h2>
      <span>
        <FloatingImageRight
          src={contactlessCardSource}
          width="150"
          alt="Contactless card over a yellow card reader"/>
        {t("gettingAround.howToPay.p1")}
      </span><p/>
      <span>
        {t("gettingAround.howToPay.p2.1")}&nbsp;
        <a href="https://tfl.gov.uk/travel-information/visiting-london/visitor-oyster-card">
          {t("gettingAround.howToPay.p2.oysterCardLink")}
        </a>&nbsp;
        {t("gettingAround.howToPay.p2.2")}
      </span><p/>
      <span>
        {t("gettingAround.howToPay.p3.1")}&nbsp;
        <a href="https://visitorshop.tfl.gov.uk/en">
          {t("gettingAround.howToPay.p3.visitorShopLink")}
        </a>
      </span><p/>
      <span>{t("gettingAround.howToPay.p4")}</span><p/>
      <span>{t("gettingAround.howToPay.p5")}</span><p/>

      <h2>{t("gettingAround.transportTypes.title")}</h2>
  
      <h2>{t("gettingAround.transportTypes.taxi.title")}</h2>
      <span>
        {t("gettingAround.transportTypes.taxi.p1")}&nbsp;
        <a href="https://gett.com/uk/">
          {t("gettingAround.transportTypes.taxi.gettLinkText")}
        </a>
      </span>
        <ul>
          <li>One-Number Taxi: 0871 871 8710</li>
          <li>Computer Cab: 020 7908 0207</li>
          <li>Dial-A-Cab: 020 7253 5000</li>
        </ul>
      <span>
        {t("gettingAround.transportTypes.taxi.minicab")}&nbsp;
        <a href="https://tfl.gov.uk/forms/12389.aspx">
          {t("gettingAround.transportTypes.taxi.minicabSearchLinkText")}
        </a>
      </span><p/>
      <span>{t("gettingAround.transportTypes.taxi.uber")}</span><p/>

      <h2>
        {t("gettingAround.transportTypes.tube.title")}&nbsp;
        <a href="https://tfl.gov.uk/travel-information/visiting-london/getting-around-london/visitor-maps">
          {t("gettingAround.transportTypes.tube.titleLinkText")}
        </a>
      </h2>
      <span>{t("gettingAround.transportTypes.tube.p1")}</span><p/>
      <span>{t("gettingAround.transportTypes.tube.p2")}</span><p/>
      <span>{t("gettingAround.transportTypes.tube.p3")}</span><p/>
      <span>
        {t("gettingAround.transportTypes.tube.p4.1")}&nbsp;
        <a href="https://tfl.gov.uk/campaign/tube-improvements/what-we-are-doing/night-tube">
          {t("gettingAround.transportTypes.tube.p4.linkText")}
        </a>&nbsp;
        {t("gettingAround.transportTypes.tube.p4.2")}
      </span><p/>
      <span>{t("gettingAround.transportTypes.tube.tips.title")}</span>
      <ul>
        <li>{t("gettingAround.transportTypes.tube.tips.tip1")}</li>
        <li>
          {t("gettingAround.transportTypes.tube.tips.tip2.1")}&nbsp;
          <a href="https://content.tfl.gov.uk/walking-tube-map-zones-1-3.pdf">
            {t("gettingAround.transportTypes.tube.tips.tip2.linkText")}
          </a>&nbsp;
          {t("gettingAround.transportTypes.tube.tips.tip2.2")}
        </li>
        <li>{t("gettingAround.transportTypes.tube.tips.tip3")}</li>
      </ul>
      <StaticImage
        src="../images/elizabeth-line-map.png"
        alt="Thames Clipper Service Map"
        placeholder="blurred"
        loading="eager"
        style={{
          minWidth: "250px",
          width: "100%"
        }} /><p/>

      <h2>{t("gettingAround.transportTypes.bus.title")}</h2>
      <span>{t("gettingAround.transportTypes.bus.p1")}</span><p/>
      <span>{t("gettingAround.transportTypes.bus.p2")}</span>
      <ul>
        <li>{t("gettingAround.transportTypes.bus.routes.11")}</li>
      </ul><p/>

      <h2>
        {t("gettingAround.transportTypes.boat.title")}&nbsp;
        <a href="https://content.tfl.gov.uk/walking-tube-map-zones-1-3.pdf">
          {t("gettingAround.transportTypes.boat.titleLinkText")}
        </a>
      </h2>
      <span>{t("gettingAround.transportTypes.boat.p1")}</span><p/>
      <span>{t("gettingAround.transportTypes.boat.tips.title")}</span>
      <ul>
        <li>{t("gettingAround.transportTypes.boat.tips.tip1")}</li>
      </ul>
      <StaticImage
        src="../images/thames-clipper-map.png"
        alt="Thames Clipper Service Map"
        placeholder="blurred"
        loading="eager"
        style={{
          minWidth: "250px",
          width: "100%"
        }} /><p/>

      <h2>{t("gettingAround.transportTypes.dlr.title")}</h2>
      <span>
        {t("gettingAround.transportTypes.dlr.p1")}&nbsp;
        <a href="https://tfl.gov.uk/maps/track/dlr">
          {t("gettingAround.transportTypes.dlr.mapLinkText")}
        </a>
      </span><p/>
        <span>{t("gettingAround.transportTypes.dlr.suggestedRoute.title")}</span>
        <ul>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.1")}</li>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.2")}</li>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.3")}</li>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.4")}</li>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.5")}</li>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.6")}</li>
          <li>{t("gettingAround.transportTypes.dlr.suggestedRoute.7")}</li>
        </ul>
      <StaticImage
        src="../images/dlr-route-map.png"
        alt="DLR route Map"
        placeholder="blurred"
        loading="eager"
        style={{
          minWidth: "250px",
          width: "100%"
        }} /><p/>

      <h2>{t("gettingAround.transportTypes.overground.title")}</h2>
      <span>
        {t("gettingAround.transportTypes.overground.p1")}&nbsp;
        <a href="https://tfl.gov.uk/maps/track/overground">
          {t("gettingAround.transportTypes.overground.mapLinkText")}
        </a>
      </span><p/>
      <StaticImage
        src="../images/overground-map.gif"
        alt="London Overground route Map"
        placeholder="blurred"
        loading="eager"
        style={{
          minWidth: "250px",
          width: "100%"
        }} />

    </Layout>

  )
}

export default TransportAndHotelsPage