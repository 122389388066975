exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-eating-out-js": () => import("./../../../src/pages/eating-out.js" /* webpackChunkName: "component---src-pages-eating-out-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-hotels-js": () => import("./../../../src/pages/hotels.js" /* webpackChunkName: "component---src-pages-hotels-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-shopping-js": () => import("./../../../src/pages/shopping.js" /* webpackChunkName: "component---src-pages-shopping-js" */),
  "component---src-pages-sightseeing-js": () => import("./../../../src/pages/sightseeing.js" /* webpackChunkName: "component---src-pages-sightseeing-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */)
}

