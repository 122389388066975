import * as React from 'react'
import ukFlag from '../images/united-kingdom.png'
import finnishFlag from '../images/finland.png'


const Flag = ({country}) => {

  const countryToFlag = {
    "en": ukFlag ,
    "fi": finnishFlag
  }
  const flagImageSource = countryToFlag[country]
    return (
    <img
        src={flagImageSource}
        width="12"
        height="12"
        alt="Flag"/>)
}

export default Flag;