import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "./languageswitcher"
import caretDownSource from "../images/caret-down-solid.svg"
import caretUpSource from "../images/caret-up-solid.svg"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  white-space: break-spaces;
  text-align: center;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  margin-left: 1em;
  display: flex;
  align-items: center;
`

const Dropdown = styled.div`
`
const DropdownToggle = styled.div`
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  white-space: break-spaces;
  text-align: center;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`
const DropdownContent = styled.div`
  transition: max-height 400ms, padding 50ms ease-in;
  overflow: hidden;
  max-height: ${props => props.hidden ? "0" : "97px"};
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  top: 8vh;
  gap: 10px;
  border-bottom: 2px solid #33333320;
  border-left: 2px solid #33333320;
  border-right: 2px solid #33333320;

  @media (max-width: 768px) {
    position: relative;
    border: none;
    top: 0;
    max-height: ${props => props.hidden ? "0" : "300px"};
    gap: 0;
  }

  :last-child {
    padding-bottom: ${props => props.hidden ? "0" : "10px"};
  }
`

const NavbarLinks = () => {
  const { t } = useTranslation("NavbarLinks");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <>
      <NavItem to="/home">Home</NavItem>
      <NavItem to="/faqs">{t("faqs")}</NavItem>
      <NavItem to="/venue">{t("venue")}</NavItem>
      <NavItem to="/transport">{t("transport")}</NavItem>
      <NavItem to="/hotels">{t("hotels")}</NavItem>
      <Dropdown>
        <DropdownToggle onClick={() => setDropdownOpen(!dropdownOpen)}>
        {t("tips")}
        <img
          src={dropdownOpen ? caretUpSource : caretDownSource}
          width="12"
          height="12"
          alt=""/>
        </DropdownToggle>
        <DropdownContent hidden={!dropdownOpen}>
          <NavItem to="/eating-out">{t("eatingOut")}</NavItem>
          <NavItem to="/sightseeing">{t("sightseeing")}</NavItem>
          <NavItem to="/shopping">{t("shopping")}</NavItem>
        </DropdownContent>
      </Dropdown>
      <StyledLanguageSwitcher/>
    </>
  )
}

export default NavbarLinks