import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { container } from './layout.module.css'
import Header from './header'

const Button = styled.button`
  position: fixed; 
  bottom: 20px; 
  right: 30px; 
  z-index: 99; 
  background: rgba(17, 17, 17, 0.1);
  cursor: pointer; 
  border-radius: 10px; 
  font-size: 18px; 
  width: 52px;
  height: 52px;

  @media (max-width: 768px) {
    display:none;
  }
`

const Layout = ({ pageTitle, showNav=true, children }) => {
  const [show, setShow] = useState(false)

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const backToTop = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })

  }
  return (
    <div className={container}>
      <title>{pageTitle}</title>
        {showNav && <Header/>}
      <main>
        {children}
        {show && <Button onClick={backToTop} title="Go to top of page">
        ▲
        </Button>}
      </main>
    </div>
  )
}

export default Layout