import * as React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import Layout from '../components/layout'

const New = styled.div`    
  color: goldenrod;
`

const FaqPage = () => {
  const { t } = useTranslation("EatingOut");
  
  return (
    <Layout pageTitle={t("title")}>
      <h1>{t("title")}</h1>
        <h2>{t("foodExperiences.title")}</h2>

        <h3>{t("foodExperiences.eataly.title")}</h3>
        <span>{t("foodExperiences.eataly.description")}</span><p/>
        
        <h3>{t("foodExperiences.mercato.title")}</h3>
        <span>{t("foodExperiences.mercato.description")}</span><p/>

        <h3>{t("foodExperiences.borough.title")}</h3>
        <span>{t("foodExperiences.borough.description")}</span><p/>

        <New>
        <h3>{t("foodExperiences.broadway.title")}</h3>
        <span>{t("foodExperiences.broadway.description")}</span><p/>
        </New>

        <p/>
        <h2>{t("bestCoffee.title")}</h2>

        <h3>{t("bestCoffee.whiteMulberries.title")}</h3>
        <span>{t("bestCoffee.whiteMulberries.description")}</span><p/>

        <h3>{t("bestCoffee.oleSteen.title")}</h3>
        <span>{t("bestCoffee.oleSteen.description")}</span><p/>

        <h3>{t("bestCoffee.everbean.title")}</h3>
        <span>{t("bestCoffee.everbean.description")}</span><p/>

        <h3>{t("bestCoffee.hagen.title")}</h3>
        <span>{t("bestCoffee.hagen.description")}</span><p/>

        <h3>{t("bestCoffee.gentlemen.title")}</h3>
        <span>{t("bestCoffee.gentlemen.description")}</span><p/>

        <h3>{t("bestCoffee.hermanos.title")}</h3>
        <span>{t("bestCoffee.hermanos.description")}</span><p/>

        <h3>{t("bestCoffee.grind.title")}</h3>
        <span>{t("bestCoffee.grind.description")}</span><p/>

        <h3>{t("bestCoffee.notes.title")}</h3>
        <span>{t("bestCoffee.notes.description")}</span><p/>

        <h3>{t("bestCoffee.gails.title")}</h3>
        <span>{t("bestCoffee.gails.description")}</span><p/>

        <h3>{t("bestCoffee.greenwich.title")}</h3>
        <ul>
          <li>{t("bestCoffee.greenwich.1")}</li>
          <li>{t("bestCoffee.greenwich.2")}</li>
          <li>{t("bestCoffee.greenwich.3")}</li>
          <li>{t("bestCoffee.greenwich.4")}</li>
          <li>{t("bestCoffee.greenwich.5")}</li>
        </ul><p/>
        

        <p/>
        <h2>{t("onTheGo.title")}</h2>

        <h3>{t("onTheGo.pure.title")}</h3>
        <span>{t("onTheGo.pure.description")}</span><p/>

        <h3>{t("onTheGo.itsu.title")}</h3>
        <span>{t("onTheGo.itsu.description")}</span><p/>

        <h3>{t("onTheGo.leon.title")}</h3>
        <span>{t("onTheGo.leon.description")}</span><p/>

        <h3>{t("onTheGo.pret.title")}</h3>
        <span>{t("onTheGo.pret.description")}</span><p/>

        <h3>{t("onTheGo.greggs.title")}</h3>
        <span>{t("onTheGo.greggs.description")}</span><p/>
        

        <p/>
        <h2>{t("italian.title")}</h2>

        <h3>{t("italian.pilgrims.title")}</h3>
        <span>{t("italian.pilgrims.description")}</span><p/>

        <h3>{t("italian.franco.title")}</h3>
        <span>{t("italian.franco.description")}</span><p/>

        <h3>{t("italian.mama.title")}</h3>
        <span>{t("italian.mama.description")}</span>
        <ul>
          <li>{t("italian.mama.gloria")}</li>
          <li>{t("italian.mama.circolo")}</li>
          <li>{t("italian.mama.aveMario")}</li>
        </ul><p/>

        <h3>{t("italian.bianco.title")}</h3>
        <span>{t("italian.bianco.description")}</span><p/>
        

        <p/>
        <h2>{t("asian.title")}</h2>

        <h3>{t("asian.stickNSushi.title")}</h3>
        <span>{t("asian.stickNSushi.description")}</span><p/>

        <h3>{t("asian.kulu.title")}</h3>
        <span>{t("asian.kulu.description")}</span><p/>

        <h3>{t("asian.shoryu.title")}</h3>
        <span>{t("asian.shoryu.description")}</span><p/>

        <h3>{t("asian.dishoom.title")}</h3>
        <span>{t("asian.dishoom.description")}</span><p/>

        <h3>{t("asian.rosas.title")}</h3>
        <span>{t("asian.rosas.description")}</span><p/>

        <h3>{t("asian.pingPong.title")}</h3>
        <span>{t("asian.pingPong.description")}</span><p/>

        <h3>{t("asian.wagamama.title")}</h3>
        <span>{t("asian.wagamama.description")}</span><p/>
        

        <p/>
        <h2>{t("burger.title")}</h2>

        <h3>{t("burger.honest.title")}</h3>
        <span>{t("burger.honest.description")}</span><p/>

        <h3>{t("burger.pattyAndBun.title")}</h3>
        <span>{t("burger.pattyAndBun.description")}</span><p/>

        <h3>{t("burger.shakeShack.title")}</h3>
        <span>{t("burger.shakeShack.description")}</span><p/>

        <h3>{t("burger.thunderbird.title")}</h3>
        <span>{t("burger.thunderbird.description")}</span><p/>

        <h3>{t("burger.wahaca.title")}</h3>
        <span>{t("burger.wahaca.description")}</span><p/>

        <h3>{t("burger.chilango.title")}</h3>
        <span>{t("burger.chilango.description")}</span><p/>
        

        <p/>
        <h2>{t("bbq.title")}</h2>

        <h3>{t("bbq.flatiron.title")}</h3>
        <span>{t("bbq.flatiron.description")}</span><p/>

        <h3>{t("bbq.hawksmoor.title")}</h3>
        <span>{t("bbq.hawksmoor.description")}</span><p/>

        <h3>{t("bbq.gaucho.title")}</h3>
        <span>{t("bbq.gaucho.description")}</span><p/>
    </Layout>
  )
}

export default FaqPage